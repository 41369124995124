// Fonts
//@import url("https://fonts.googleapis.com/css?family=Nunito");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");

// Variables
@import "variables";

// Bootstrap
// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/offcanvas";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";

.font-roboto {
  font-family: "Roboto", sans-serif;
}

.card {
    border: 1px solid $border-color;
    border-radius: 5px;
}
.shadow {
    box-shadow: 0 0.15rem 1.75rem 0 rgb(58 59 69 / 15%) !important;
}

.btn-primary {
    color: #ffffff;
}

.btn-danger {
    color: #ffffff;
}

.btn-whatsapp {
  background-color:#25D366;
  bottom: 10px;
  right:10px;
  z-index:10;
}

.turbo-progress-bar {
    height: 5px;
    background-color: $teal;
}

@media (max-width: 575px) {
    .rounded-xs-0 {
        border-radius: 0px !important;
    }
    .btn-whatsapp {
      width: calc(100% - 20px);
    }
}

.topnav-link {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    color: rgb(18, 158, 148) !important;
}

.footer-dark {
    padding: 50px 0;
    color: #f0f9ff;
    background-color: #282d32;
}

.footer-dark h3 {
    margin-top: 0;
    margin-bottom: 12px;
    font-weight: bold;
    font-size: 16px;
}

.footer-dark ul {
    padding: 0;
    list-style: none;
    line-height: 1.6;
    font-size: 14px;
    margin-bottom: 0;
}

.footer-dark ul a {
    color: inherit;
    text-decoration: none;
    opacity: 0.6;
}

.footer-dark ul a:hover {
    opacity: 0.8;
}

@media (max-width: 767px) {
    .footer-dark .item:not(.social) {
        text-align: center;
        padding-bottom: 20px;
    }
}

.footer-dark .item.text {
    margin-bottom: 36px;
}

@media (max-width: 767px) {
    .footer-dark .item.text {
        margin-bottom: 0;
    }
}

.footer-dark .item.text p {
    opacity: 0.6;
    margin-bottom: 0;
}

.footer-dark .item.social {
    text-align: center;
}

@media (max-width: 991px) {
    .footer-dark .item.social {
        text-align: center;
        margin-top: 20px;
    }
}

.footer-dark .item.social > a {
    font-size: 20px;
    width: 36px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
    margin: 0 8px;
    color: #fff;
    opacity: 0.75;
}

.footer-dark .item.social > a:hover {
    opacity: 0.9;
}

.footer-dark .copyright {
    text-align: center;
    padding-top: 24px;
    opacity: 0.3;
    font-size: 13px;
    margin-bottom: 0;
}

.features-boxed {
    color: #313437;
    background-color: #eef4f7;
}

.features-boxed p {
    color: #7d8285;
}

.features-boxed h2 {
    font-weight: bold;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit;
}

@media (max-width: 767px) {
    .features-boxed h2 {
        margin-bottom: 25px;
        padding-top: 25px;
        font-size: 24px;
    }
}

.features-boxed .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
}

.features-boxed .intro p {
    margin-bottom: 0;
}

.features-boxed .features {
    padding: 50px 0;
}

.features-boxed .item {
    text-align: center;
}

.features-boxed .item .box {
    text-align: center;
    padding: 30px;
    background-color: #fff;
    margin-bottom: 30px;
}

.features-boxed .item .icon {
    font-size: 60px;
    color: #1485ee;
    margin-top: 20px;
    margin-bottom: 35px;
}

.features-boxed .item .name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 0;
    color: inherit;
}

.features-boxed .item .description {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.newsletter-subscribe {
    color: #313437;
    background-color: #fff;
    padding: 50px 0;
  }
  
  .newsletter-subscribe p {
    color: #7d8285;
    line-height: 1.5;
  }
  
  .newsletter-subscribe h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 25px;
    line-height: 1.5;
    padding-top: 0;
    margin-top: 0;
    color: inherit;
  }
  
  .newsletter-subscribe .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto 25px;
  }
  
  .newsletter-subscribe .intro p {
    margin-bottom: 35px;
  }
  
  .newsletter-subscribe form {
    justify-content: center;
  }
  
  .newsletter-subscribe form .form-control {
    background: #eff1f4;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    outline: none;
    color: inherit;
    text-indent: 9px;
    height: 45px;
    margin-right: 10px;
    min-width: 250px;
  }
  
  .newsletter-subscribe form .btn {
    padding: 16px 32px;
    border: none;
    background: none;
    box-shadow: none;
    text-shadow: none;
    opacity: 0.9;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.4px;
    line-height: 1;
  }
  
  .newsletter-subscribe form .btn:hover {
    opacity: 1;
  }
  
  .newsletter-subscribe form .btn:active {
    transform: translateY(1px);
  }
  
  .newsletter-subscribe form .btn-primary {
    background-color: #055ada !important;
    color: #fff;
    outline: none !important;
  }
  
  @media (min-width: 576px) {
    .newsletter-subscribe form .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
  }

  .article-list {
    color: #313437;
    background-color: #fff;
  }
  
  .article-list p {
    color: #7d8285;
  }
  
  .article-list h2 {
    font-weight: bold;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit;
  }
  
  @media (max-width:767px) {
    .article-list h2 {
      margin-bottom: 25px;
      padding-top: 25px;
      font-size: 24px;
    }
  }
  
  .article-list .intro {
    font-size: 16px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .article-list .intro p {
    margin-bottom: 0;
  }
  
  .article-list .articles {
    padding-bottom: 40px;
  }
  
  .article-list .item {
    padding-top: 50px;
    text-align: center;
  }
  
  .article-list .item .name {
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;
    color: inherit;
  }
  
  .article-list .item .description {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0;
  }
  
  .article-list .item .action {
    font-size: 24px;
    width: 24px;
    margin: 22px auto 0;
    line-height: 1;
    display: block;
    color: #4f86c3;
    opacity: 0.85;
    transition: opacity 0.2s;
    text-decoration: none;
  }
  
  .article-list .item .action:hover {
    opacity: 1;
  }

  .cardimg-cover {
      height: 220px;
      position: relative;
      overflow: hidden;
      .card-img-top {
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          position: relative;
          display: block;
      }
  }

  .cardimg-bg {
    height: 100%;
    width: 100%;
    position: absolute;
    filter: blur(10px) brightness(80%);
    transform: scale(1.1);
    background-size: cover;
    background-position: center;
  }